import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import iconPicker from 'vue-fontawesome-elementui-icon-picker'
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
// this.$md5('MD5加密')
Vue.use(iconPicker)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
axios.defaults.baseURL = 'http://127.0.0.1:8088/' // (后端连接端口号)
// 请求拦截器
axios.interceptors.request.use(
  config => {
    //  token && (config.headers.Authorization = token)
    const token = sessionStorage.getItem('token')
    token && (config.headers.token = token)
    return config
  },
  error => {
    return Promise.error(error)
  })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
